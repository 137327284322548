import * as React from "react"
import { PopupButton } from "@typeform/embed-react"

export default function Footer() {
  return (
    <div className="flex flex-wrap justify-between my-16 px-6 md:px-10">
      <div className="flex md:mr-24">
        <div className="mr-24">
          <h2 className="font-bold text-3xl mb-4">Contact Us</h2>
          <p className="mb-3">Serving Northeast Ohio and Beyond.</p>
          <p>
            <span className="font-bold">P:</span>{" "}
            <a href="tel:3309834663">330.983.4663</a>
          </p>
          <p>
            <span className="font-bold">E:</span>{" "}
            <a href="mailto: hello@i360-llc.com">hello@i360-llc.com</a>
          </p>
        </div>
        <div>
          <h2 className="font-bold text-3xl mb-4">Support</h2>
          {/* Z9ILfSSB */}
          <PopupButton id="Z9ILfSSB" className="flex group">
            Submit a Ticket{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-4 ml-1 group-hover:ml-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </PopupButton>
          {/* <button className="flex group">Submit a Ticket <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-4 ml-1 group-hover:ml-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg></button> */}
        </div>
      </div>

      {/* <div className="mt-24 md:mt-0">
        <h2 className="font-bold text-3xl mb-4">Subscribe to Our Newsletter</h2>
        <p className="max-w-md">
          Join our newsletter to get the latest news and tips on tech and
          security for your business!
        </p>
        <div className="flex justify-between mt-2">
          <input
            placeholder="Email Address"
            type="text"
            className="w-80 mr-2 py-2 px-4 border-2 border-gray-300 rounded-md"
          ></input>
          <button className="w-40 px-4 rounded-md text-white bg-insight">
            Submit
          </button>
        </div>
      </div> */}
    </div>
  )
}
